import React from "react";
import { graphql } from "gatsby";
import ClientLayout from "../../components/ClientLayout";

import player from "../../../static/themes/hyped/../../../static/themes/hyped/assets/images/landing/player.png";
import hero_bg from "../../../static/themes/hyped/assets/images/landing/hero-bg.png";
import hero_phone from "../../../static/themes/hyped/assets/images/landing/hero-phone.png";
import grist_mock from "../../../static/themes/hyped/assets/images/landing/grist-mock.png";
import ai_mock from "../../../static/themes/hyped/assets/images/landing/ai-mock.png";
import docpanel_mock from "../../../static/themes/hyped/assets/images/landing/docpanel-mock.png";
import seo_i from "../../../static/themes/hyped/assets/images/landing/icons/seo-i.svg";
import small_arrow from "../../../static/themes/hyped/assets/images/landing/icons/small-arrow.svg";
import adwords_i from "../../../static/themes/hyped/assets/images/landing/icons/adwords-i.svg";
import conversions_i from "../../../static/themes/hyped/assets/images/landing/icons/conversions-i.svg";
import streamline_user_i from "../../../static/themes/hyped/assets/images/landing/icons/streamline-user-i.svg";
import form_animated from "../../../static/themes/hyped/assets/videos/form-animated.mp4";
import aiui from "../../../static/themes/hyped/assets/videos/aiui.mp4";

import backgroundAI from '../../../static/themes/hyped/assets/images/landing/ai-mock-bg.jpg'
import backgroundDocpanel from '../../../static/themes/hyped/assets/images/landing/docpanel-mock-bg.png'
import backgroundGrist from '../../../static/themes/hyped/assets/images/landing/grist-mock-bg.png'


export const query = graphql`
  {
    logo: contentfulMedia(slug: { eq: "site-logo" }) {
      media {
        fluid(maxHeight: 160, maxWidth: 1194) {
          src
        }
      }
      slug
    }
  }
`;

export default function WebDesign({ data }) {
  return (
    <ClientLayout>
      <div className="header">
        <div className="header__container">
          <div className="header__row">
            <div className="header__logo mx-auto mx-md-0">
            <a className="ajax-link" data-type="page-transition" href="/">
                <img
                  src={data.logo.media.fluid.src}
                  className="archive-img-fluid"
                  alt={data.logo.slug}
                />
              </a>
            </div>

            <div className="header__button d-md-block">
              <a data-type="page-transition" href="/contact" 
                className="ajax-link button button--dark button--transparent button--rounded"
              >
                Ready for a quote?
              </a>
            </div>
          </div>
        </div>
      </div>

      <section className="section hero-lp">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-lg-6 mx-xxl-auto">
              <ul className="hero-lp__breadcrumbs">
                <li>Services</li>
                <li className="is-active">Web design</li>
              </ul>
              <h2 className="lp-title">
                We build dynamic, engaging websites that convert.
              </h2>
              <div className="hero-lp__form mt-5 mx-auto mx-lg-0">
                <h3>Let’s chat about your business needs</h3>
                <p>
                  We'll reach out within 1 business day to schedule some time to
                  chat
                </p>
                {/* {% component 'leadFormSmall' %} */}
              </div>
            </div>
            <div className="col-lg-5  ml-lg-auto">
              <div className="hero-lp__wrap">
                <div className="hero-lp__player">
                  <div id="js-player-drawing"></div>
                  <img
                    src={player}
                    className="img-fluid"
                    alt="Football Player"
                  />
                </div>
                <div className="hero-lp__stripe">
                  <img src={hero_bg} className="img-fluid" alt="Stripe" />
                </div>
                <div className="hero-lp__phone">
                  <img src={hero_phone} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-lp grist"  style={{backgroundImage: `url(${backgroundGrist})`}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-lp__image">
                <img src={grist_mock} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5 mx-lg-auto order-1 order-lg-2">
              <p className="section-lp__subtitle is-orange">
                Love at first click
              </p>
              <h2 className="section-lp__title">
                Design that makes the best first impression
              </h2>
              <p className="section-lp__description">
                1/10th of second is all it takes for users to form an impression
                of your brand, informing whether or not they’ll engage with your
                product.{" "}
              </p>
              <p className="section-lp__description">
                Your website is the first point of contact, meaning that good
                user experience is essential in attracting potential customers.
                We embed best practices powered by behavioral research and user
                insights in order to create amazing user-driven experiences
                aimed at doing one thing: achieving your business goals.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-lp ai" style={{backgroundImage: `url(${backgroundAI})`}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-4 col-lg-5 mx-lg-auto">
              <p className="section-lp__subtitle is-blue">
                Dynamic User Experience
              </p>
              <h2 className="section-lp__title">
                Customer-centric strategy that drives action, on any platform
              </h2>
              <p className="section-lp__description">
                Your customers interface with your brand through a variety of
                platforms, requiring a seamless experience regardless of device
                or browser.{" "}
              </p>
              <p className="section-lp__description">
                From engaging interactions to mobile-specific features, a
                responsive website is the ultimate shape shifter. It should
                become what a user needs it to be, based on device type and
                screen resolution. It also needs to be fast-loading and pixel
                perfect on a variety of web browsers, new and old.
              </p>

              <div className="d-md-flex align-items-center mt-4 feature_container">
                <div className="section-lp__feature">
                  <div className="section-lp__feature-icon">
                    <img src={small_arrow} className="img-fluid" alt="" />
                  </div>
                  <div className="section-lp__feature-text">
                    <h3>Interactive</h3>
                    <p>
                      Whether it’s subtle touches, or full page animations, we
                      deliver engaging content that elevates your brand.
                    </p>
                  </div>
                </div>
                <div className="section-lp__feature">
                  <div className="section-lp__feature-icon">
                    <img src={small_arrow} className="img-fluid" alt="" />
                  </div>
                  <div className="section-lp__feature-text">
                    <h3>Functional</h3>
                    <p>
                      You’ll never have to worry about showing off your website
                      on Grandma’s beloved Windows 98’.
                    </p>
                  </div>
                </div>
                <div className="section-lp__feature">
                  <div className="section-lp__feature-icon">
                    <img src={small_arrow} className="img-fluid" alt="" />
                  </div>
                  <div className="section-lp__feature-text">
                    <h3>Streamlined</h3>
                    <p>
                      We implement research driven best practices to get users
                      from A to B without jumping through hoops.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-lp__image">
                {/* <img src={ai_mock} className="img-fluid" alt="" /> */}
                <div className="section-lp__video">
                  <video
                    src={aiui}
                    poster={ai_mock}
                    muted
                    autoPlay
                    loop
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-lp docpanel"  style={{backgroundImage: `url(${backgroundDocpanel})`}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-lp__image">
                <img src={docpanel_mock} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5 mx-lg-auto order-1 order-lg-2">
              <p className="section-lp__subtitle is-green">
                Track what matters
              </p>
              <h2 className="section-lp__title">
                Data-driven insights that crush your KPIs
              </h2>
              <p className="section-lp__description">
                Users are constantly communicating through every pageview,
                click, or bounce. It is mission critical to distill these
                micro-behaviors into clear and actionable insights.
                Understanding what the metrics mean and how to respond is the
                difference between winning and losing in the digital economy. We
                provide the missing link in understanding which KPIs are driving
                business and which are just white noise.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-lp advantages">
        <div className="container">
          <h2 className="section-lp__title d-table mx-auto">
            Growing your business is our business
          </h2>
          <p className="lp-description d-table mx-auto">
            As a strategic partner, we’ll integrate with all aspects of your
            marketing initiatives to increase sales, drive engagement, and
            improve your digital presence.
          </p>
          <div className="row mt-5">
            <div className="col-xxl-3 col-md-6 col-lg-3">
              <div className="advantages__box">
                <img src={seo_i} className="img-fluid" alt="" />
                <h3>Increase SEO</h3>
                <p>
                  Proper ROI-driven SEO will drive additional organic traffic to
                  your site and ensure you’re driving qualified leads and
                  increasing your digital visibility while lowering your
                  acquisition costs.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-lg-3">
              <div className="advantages__box">
                <img src={adwords_i} className="img-fluid" alt="" />
                <h3>Optimize Adwords</h3>
                <p>
                  Leverage website interactions to optimize your adwords
                  campaigns by tracking conversions to specific keywords and ad
                  versions. This ensures you allocate budget to your best
                  performing ad strategies.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-lg-3">
              <div className="advantages__box">
                <img src={conversions_i} className="img-fluid" alt="" />
                <h3>Maximize Conversions</h3>
                <p>
                  A/B test your pages (layout, copy, CTAs, forms, etc) and add
                  personalization features to maximize conversions from your
                  website traffic. We use an iterative process to continually
                  enhance site performance.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-lg-3">
              <div className="advantages__box">
                <img src={streamline_user_i} className="img-fluid" alt="" />
                <h3>Streamline User Flow</h3>
                <p>
                  Track & evaluate your traffic behavior through the different
                  paths customers are taking to get where you want them to go
                  and use this data to streamline your site’s user flow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-lp section-lp" id="contact">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <p className="lp-subtitle is-blue">WE’RE ALL EARS</p>
              <h2 className="lp-title">Ready to get started?</h2>

              <div className="contact-lp__form">
                <h3>Basic Info</h3>
                {/* {% component 'leadForm' %} */}
              </div>
            </div>
            <div className="col-lg-6 ml-lg-auto d-none d-lg-block">
              <video src={form_animated} muted autoPlay></video>
            </div>
          </div>
        </div>
      </section>
    </ClientLayout>
  );
}
